import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const langSelect = document.querySelectorAll('[data-language-select]')

  if (langSelect.length) {
    for (let i = 0; i < langSelect.length; i++) {
      langSelect[i].addEventListener('change', function () {
        window.location.href = langSelect[i].value
      })
    }
  }
})
