import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'
import AsyncSection from '@/js/helpers/async-section'

class AsyncBlog extends AsyncSection {
  constructor (el) {
    super(el)

    // eslint-disable-next-line
    this.pageIndex = JSON.parse(qv).paged ? JSON.parse(qv).paged : 1
  }

  getParams () {
    return {
      action: 'blog_posts',
      paged: this.pageIndex
    }
  }

  beforeNavigation () {
    gsap.to(this.el, 0.2, {
      opacity: 0.8
    })
  }

  navigationSuccess (response) {
    gsap.to(this.el, 0.2, {
      opacity: 1
    })

    gsap.to('.blog-section__article', 0.2, {
      opacity: 0,
      y: 10,
      stagger: 0.05,
      onComplete: () => {
        window.history.pushState('', '', `${window.blogUrl}page/${this.pageIndex}/`)
        if (this.el.firstChild) {
          while (this.el.firstChild) {
            this.el.removeChild(this.el.firstChild)
          }
        }

        window.scrollTo({ top: 0, behavior: 'smooth' })

        this.el.insertAdjacentHTML('beforeEnd', response.data)
        gsap.fromTo('.blog-section__article', 0.4, {
          opacity: 0,
          y: 10
        }, {
          opacity: 1,
          y: 0,
          stagger: 0.05
        })
        this.initEvents()
      }
    })
  }
}

docReady(() => {
  const blogEl = document.getElementById('ajax-blog')
  if (blogEl) {
    // eslint-disable-next-line
    const asyncBlog = new AsyncBlog(blogEl)
  }
})
