import 'promise-polyfill/src/polyfill'

// Modernizr
import 'root/.modernizrrc'

// Stylesheets
import '@/scss/style'

// Scripts
import '@/js/components/navigation'
import '@/js/components/faq'
import '@/js/components/ajax-blog'
// import '@/js/components/ajax-archive'
import '@/js/components/sliders'
import '@/js/components/lang-switch'
import '@/js/components/ie'
import '@/js/components/book-now'
import '@/js/components/accordion'
