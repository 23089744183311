const axios = require('axios')

export default class AsyncSection {
  constructor (el) {
    this.el = el

    // eslint-disable-next-line
    this.pageIndex = JSON.parse(qv).paged ? JSON.parse(qv).paged : 1

    this.initEvents()
    window.addEventListener('popstate', this.popGo.bind(this))
  }

  getParams () {
    return {
      paged: this.pageIndex
    }
  }

  goToNextPage (e) {
    e.preventDefault()
    this.pageIndex++
    this.goToPage()
  }

  goToPreviousPage (e) {
    e.preventDefault()
    this.pageIndex--
    this.goToPage()
  }

  popGo (e) {
    // eslint-disable-next-line
    window.location.href = window.location.href
  }

  beforeNavigation () {
    //
  }

  navigationSuccess (response) {
    this.initEvents()
  }

  navigationError (error) {
    this.initEvents()
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
    console.log(error.config)
  }

  goToPage () {
    this.beforeNavigation()

    this.removeEvents()

    const params = this.getParams()

    axios.get(ajaxurl, {
      params
    })
      .then(response => {
        this.navigationSuccess(response)
      })
      .catch(error => {
        this.navigationError(error)
      })
  }

  removeEvents () {
    if (this.nextButtons.length) {
      for (let i = 0; i < this.nextButtons.length; i++) {
        this.nextButtons[i].removeEventListener('click', this.goToNextPage.bind(this))
      }
    }
    if (this.prevButtons.length) {
      for (let i = 0; i < this.prevButtons.length; i++) {
        this.prevButtons[i].removeEventListener('click', this.goToPreviousPage.bind(this))
      }
    }
  }

  initEvents () {
    this.prevButtons = this.el.querySelectorAll('[data-previous]')
    this.nextButtons = this.el.querySelectorAll('[data-next]')
    // TO DO
    // scroll to top
    if (this.nextButtons.length) {
      for (let i = 0; i < this.nextButtons.length; i++) {
        this.nextButtons[i].addEventListener('click', this.goToNextPage.bind(this))
      }
    }
    if (this.prevButtons.length) {
      for (let i = 0; i < this.prevButtons.length; i++) {
        this.prevButtons[i].addEventListener('click', this.goToPreviousPage.bind(this))
      }
    }
  }
}
