import { throttle } from '@/js/helpers/throttle'
import docReady from '@/js/helpers/doc-ready'

class Navigation {
  constructor () {
    this.body = document.body
    this.hamburgers = this.body.querySelectorAll('.nav-hamburger')
    this.wrapper = this.body.querySelector('.wrapper')
    this.menus = this.body.querySelectorAll('.nav-mobile, .nav-desktop')
    this.toggleElements = this.body.querySelectorAll('.navbar-toggle')

    this.initEvents()
  }

  menuSlideIn () {
    for (let i = 0; i < this.hamburgers.length; i++) {
      this.hamburgers[i].classList.remove('nav-hamburger--is-active')
    }
    this.wrapper.classList.add('wrapper--slide')
  }

  toggleMenu () {
    // And toggle the active class
    this.body.classList.toggle('body--slide-active')

    if (this.body.classList.contains('body--slide-active')) {
      for (let i = 0; i < this.menus.length; i++) {
        this.menus[i].classList.add('nav-mobile--active')
      }

      for (let i = 0; i < this.hamburgers.length; i++) {
        this.hamburgers[i].classList.add('nav-hamburger--is-active')
      }
    } else {
      for (let i = 0; i < this.menus.length; i++) {
        this.menus[i].classList.remove('nav-mobile--active')
      }
      this.menuSlideIn()
    }
  }

  headerScroll (ws) {
    if (ws > 30) {
      this.body.classList.add('menu-scroll')
      for (let i = 0; i < this.menus.length; i++) {
        this.menus[i].classList.add('menu-scroll')
      }
    } else {
      this.body.classList.remove('menu-scroll')
      for (let i = 0; i < this.menus.length; i++) {
        this.menus[i].classList.remove('menu-scroll')
      }
    }

    this.body.classList.toggle('further-scroll', ws > 300)
  }

  initEvents () {
    const clickHandler = () => this.toggleMenu()

    for (let i = 0; i < this.toggleElements.length; i++) {
      this.toggleElements[i].addEventListener('click', clickHandler)
    }

    window.addEventListener('scroll', throttle(() => {
      const ws = window.pageYOffset
      this.headerScroll(ws)
    }, 100))

    this.headerScroll(0)
  }
}

docReady(() => {
  // eslint-disable-next-line
  const nav = new Navigation()
})
