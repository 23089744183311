import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const faqQuestions = document.querySelectorAll('.faq-list__q')

  if (faqQuestions.length) {
    for (let i = 0; i < faqQuestions.length; i++) {
      const curr = faqQuestions[i]
      curr.addEventListener('click', function () {
        curr.parentNode.classList.toggle('faq-list__item--active')
      })
    }
  }

  const accordionEls = document.querySelectorAll('[data-accordion]')

  if (accordionEls.length) {
    for (let i = 0; i < accordionEls.length; i++) {
      const curr = accordionEls[i]
      const header = curr.querySelector('[data-accordion-header]')

      if (header) {
        header.addEventListener('click', function () {
          curr.classList.toggle('accordion-open')
        })
      }
    }
  }
})
