import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const callForm = document.querySelector('.arrangement-form-holder--call')

  if (callForm) {
    const heading = callForm.querySelector('.gform_heading')
    // const body = callForm.querySelector('form')

    heading.addEventListener('click', () => {
      callForm.classList.toggle('arrangement-form-holder--active')
      // body.classList.toggle()
    })
  }
})
