import { throttle } from '@/js/helpers/throttle'
import docReady from '@/js/helpers/doc-ready'

class BookNow {
  constructor (link, book, bar) {
    this.body = document.body
    this.link = link
    this.book = book
    this.bar = bar

    this.initEvents()
  }

  isElementInViewport (el) {
    const rect = el.getBoundingClientRect()

    const wh = (window.innerHeight || document.documentElement.clientHeight)

    if ((rect.bottom > (wh / 2) && rect.top < 100)) {
      return true
    } else {
      return (
        rect.top >= 100 &&
        rect.bottom - rect.height <= wh
      )
    }
  }

  headerScroll (ws) {
    const linkInView = this.isElementInViewport(this.link)
    const bookInView = this.isElementInViewport(this.book)

    const rect = this.book.getBoundingClientRect()

    console.log(bookInView, rect.bottom > 100, rect.height)

    this.body.classList.toggle('further-scroll', !linkInView && !bookInView)
  }

  initEvents () {
    window.addEventListener('scroll', throttle(() => {
      const ws = window.pageYOffset
      this.headerScroll(ws)
    }, 100))

    this.headerScroll(0)
  }
}

docReady(() => {
  const link = document.querySelector('.single-activity-price')
  const book = document.querySelector('.ticket-order')
  const bar = document.querySelector('.fixed-action-bar')
  if (link && book && bar) {
    // eslint-disable-next-line
    const bookNow = new BookNow(link, book, bar)
  }
})
